import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import OrderName from "../orderCart/components/OrderName";
import OrderStatus from "../orderCart/components/OrderStatus";
import ErrorMessage from "../orderCart/components/ErrorMessage";
import UsageInstructions from "../orderCart/components/UsageInstructions";
import ShippingAddress from "./components/ShippingAddress";
import BillDetails from "./components/BillDetails";
import OrderButtons from "../orderCart/components/OrderButtons";
import { ReactComponent as EllipseIcon } from "../../asset/img/Ellipse.svg";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import { addToCartOrderedProduct, postOverAllFeedback, fetchOrderListing } from "../../store/order/actions";

import { useQuery } from "../../helpers";

import "../../asset/scss/base.scss";
import "../../asset/scss/form.scss";
import "./styles.scss";
import ChatWithUs from "../orderCart/components/ChatWithUs";
import Layout from "../../components/Layout";
import MainHeader from "../MainHeader";
import CartItemAddToCart from "../orderCart/components/OrderProducts/_builder/ItemAddToCart";
import CustomRating from "../rating/CustomRating";
import { OrderNotFound } from "./components/orderNotFound";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
`;

export default function OrderDetails({ match, history }) {
  const scrollRef = useRef(null);
  const {
    params: { orderName },
  } = match;
  const [overallRating, setOverallRating] = useState(0);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const userOrders = useSelector((state) => state.order);
  const { customer_id, orders } = userOrders;
  const [currentOrder, setCurrentOrder] = useState(null);
  const [items, setItems] = useState(false);

  useEffect(() => {
    // if (currentOrder) scrollRef.current.scrollIntoView({ behaviour: "smooth" });
    if (currentOrder && currentOrder.order_items) {
      window.scrollTo(0, scrollRef.current?.offsetTop - 100);
    }
  }, [currentOrder]);

  // useEffect(() => {
  //   dispatch(fetchOrderListing());
  // }, [dispatch]);

  // useEffect(() => {
  //   dispatch(fetchOrderDetailsPage(orderName));
  // }, [orderName, dispatch]);

  const location = useQuery();

  let cartId = location.get("cart_id");
  if (cartId && cartId.includes("cart_token")) {
    window.location.href = `?cart_id=${cartId.split("=")[1]}`;
  }

  useEffect(() => {
    let access_token = Cookies.get("access_token");
    if (access_token == "" || access_token == undefined || access_token == null) {
      window.location.href = `/account/login`;
    } else {
      dispatch(fetchOrderListing(null, orderName)).then(() => {
        setLoading(false);
      });
    }
  }, [dispatch, orderName]);

  useEffect(() => {
    if (orders.length > 0) {
      setCurrentOrder(orders.find((order) => order?.order_name === orderName));
    }
  }, [orders, orderName]);

  const cartAdd = (quantity, variant_id, product_id, product_name, cartId, cartID) => {
    dispatch(addToCartOrderedProduct(quantity, variant_id, product_id, product_name, cartId, cartID));
  };

  return (
    <Layout>
      <MainHeader />
      {loading ? (
        <div className="flex items-center justify-center h-[calc(100vh-48px)]">
          <ClipLoader color="#5c5c5c" loading={loading} css={override} size={50} />
        </div>
      ) : orders.length === 0 ? (
        <OrderNotFound subtitle={`The order you are looking for does not exist!`} buttonText="Go Back" />
      ) : currentOrder ? (
        <div className="order-details-container">
          <div ref={scrollRef}>
            <OrderName orderName={currentOrder.order_name} />
          </div>

          <OrderStatus trackingInfo={currentOrder.tracking_info} />

          {currentOrder.error_message?.message ? (
            <ErrorMessage
              message={currentOrder.error_message.message}
              message_type={currentOrder.error_message.message_type}
            />
          ) : null}

          <div className="order-details-items-container">
            {currentOrder.order_items.map((item, idx) => (
              <div key={`${item.product_id}${idx}`} className="order-item-container">
                <div className="order-item-sub-container-1">
                  <div className="order-item-image-container">
                    <img className="order-item-image" src={item.featured_image} alt="Product" />
                  </div>
                  <div className="order-item-info-container">
                    <div className="order-name-manufacture">
                      <div className="order-item-info">
                        {item.product_name && (
                          <div
                            className="order-item-name !font-bold"
                            dangerouslySetInnerHTML={{ __html: item.product_name }}
                          />
                        )}
                        {item.variant_name && (
                          <div className="order-item-quantity-container !mb-[6px]">
                            <span className="order-item-quantity-value">
                              <i>{item.variant_name}</i>
                            </span>
                          </div>
                        )}
                        <div className="order-item-quantity-container">
                          <span className="order-item-quantity-label">Quantity:&nbsp;</span>
                          <span className="order-item-quantity-value">{item.quantity}</span>
                        </div>
                      </div>
                      {/* {item.mfg_message ? (
                        <div className="manufacture-date-info-container">
                          <div className="manufacture-date-icon">
                            <EllipseIcon />
                          </div>
                          <span className="manufacture-date-info">{item.mfg_message}</span>
                        </div>
                      ) : null} */}
                    </div>
                    <div className="control-buttons-group">
                      {item?.ui?.show_add_to_cart ? <CartItemAddToCart variantId={item.variant_id} /> : null}
                    </div>
                  </div>
                </div>

                <div className="low-resolution-devices">
                  {item?.ui?.show_add_to_cart ? <CartItemAddToCart variantId={item.variant_id} /> : null}
                </div>

                {item.usage_instructions ? <UsageInstructions usageDirections={item.usage_instructions} /> : null}
                {item?.ui?.show_rate_product ? (
                  <div className="product-rate-container">
                    <div className="rating-label">
                      <span>Rate Product</span>
                    </div>
                    <div className="rate-product-link" key={item.product_id}>
                      <CustomRating
                        size={"small"}
                        value={item.overall_rating ? item.overall_rating : overallRating}
                        onChange={(value) => {
                          setOverallRating(value);
                          dispatch(
                            postOverAllFeedback({
                              order_name: currentOrder.order_name,
                              product_id: item.product_id,
                              variant_id: item.variant_id,
                              customer_id: customer_id,
                              overall_rating: value,
                              history: { ...history, redirect: true },
                            })
                          );
                        }}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
          </div>

          <div className="shipping-and-bill-details">
            <ShippingAddress shippingAddress={currentOrder.shipping_address}>
              <OrderButtons
                showTrackButton={currentOrder.ui.show_track_button}
                showReorderButton={currentOrder.ui.show_reorder_button}
                orderItems={currentOrder.ui.order_items}
                tracking_url={currentOrder.tracking_url}
                cartId={cartId}
              />
              <ChatWithUs orderName={currentOrder.order_name} />
            </ShippingAddress>

            <BillDetails
              itemTotalPrice={currentOrder.item_total_price}
              discount={currentOrder.discount}
              shipping={currentOrder.shipping}
              subTotalPrice={currentOrder.sub_total_price}
              payment_charges={currentOrder.payment_charges}
              promotion_discount_amount={currentOrder.promotion_discount_amount}
              redeemed_nat_cash={currentOrder.redeemed_nat_cash}
              additional_off_value={currentOrder.additional_off_value}
            />
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center h-[calc(100vh-48px)]">
          <ClipLoader color="#5c5c5c" loading={loading} css={override} size={50} />
        </div>
      )}
    </Layout>
  );
}
